body {
  font-family: 'Titillium Web', sans-serif;
  color: #333333; }

img {
  max-width: 100%;
  height: auto; }

* {
  box-sizing: border-box; }

h1, h2, h3 {
  font-family: 'Montserrat', serif;
  font-weight: 400; }

::-moz-selection {
  background: #FF4500;
  color: white; }

::selection {
  background: #FF4500;
  color: white; }

.contact {
  background: white; }
  .contact-row {
    max-width: 1000px;
    margin: 0 auto 5rem; }
  .contact-form {
    margin: 0 auto;
    text-align: left;
    max-width: 650px; }
    .contact-form > div {
      margin-bottom: 1.5rem; }
    .contact-form-top-name {
      margin-bottom: 1.5rem; }
    @media (min-width: 770px) {
      .contact-form-top {
        display: flex;
        justify-content: space-between; }
        .contact-form-top-name, .contact-form-top-email {
          width: 45%;
          margin-bottom: 0; } }
    .contact-form input, .contact-form textarea {
      font-size: 1.3rem;
      width: 100%;
      padding: .4rem;
      margin-top: 1rem; }
    .contact-form ::-webkit-input-placeholder {
      color: #b3b3b3; }
    .contact-form :-ms-input-placeholder {
      color: #b3b3b3; }
    .contact-form ::-ms-input-placeholder {
      color: #b3b3b3; }
    .contact-form ::placeholder {
      color: #b3b3b3; }
    .contact-form input[type="submit"] {
      color: #54008b;
      background: white;
      font-weight: bolder;
      text-transform: uppercase;
      width: auto;
      font-size: 1.3rem;
      padding: 1rem;
      border: 3px solid #54008b;
      box-shadow: inset 0 0 #54008b;
      transition: all 0.2s ease-out; }
      .contact-form input[type="submit"]:hover, .contact-form input[type="submit"]:focus {
        cursor: pointer;
        color: white;
        box-shadow: inset 5em 0 #54008b; }
    .contact-form textarea {
      resize: none; }
    .contact-form label {
      font-size: 1.1rem;
      font-weight: bolder; }

.about-container {
  display: flex;
  margin: 0 auto;
  padding: 20px 0;
  align-items: center;
  max-width: 760px;
  flex-direction: column;
  justify-content: center; }
  .about-container p {
    font-size: 1.09rem;
    max-width: 600px;
    line-height: 2rem;
    text-align: left;
    color: #757575; }
  @media (min-width: 770px) {
    .about-container {
      flex-direction: row;
      text-align: left;
      padding: 70px 0; } }

.profile {
  border-radius: 200px;
  height: 200px;
  background: lightblue; }
  @media (min-width: 770px) {
    .profile {
      margin-right: 30px; } }

.footer {
  background-color: #202124;
  color: white;
  font-size: 1em;
  margin: 0px;
  padding-bottom: 1rem;
  text-align: center; }
  .footer svg {
    fill: white;
    opacity: .3; }
    .footer svg:hover {
      opacity: 1; }
  .footer p {
    margin-top: 0; }

.main {
  position: relative;
  color: white;
  height: 100vh; }
  .main #skyline, .main #skyline-mobile {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    -webkit-filter: drop-shadow(3px 3px 3px black);
            filter: drop-shadow(3px 3px 3px black); }
  .main #skyline {
    display: none; }
    @media (min-width: 770px) {
      .main #skyline {
        display: block; } }
  .main #skyline-mobile {
    bottom: 1rem; }
    @media (min-width: 770px) {
      .main #skyline-mobile {
        display: none; } }
  .main:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2rem;
    background: white;
    left: 0;
    bottom: 0;
    z-index: 2; }
    @media (min-width: 770px) {
      .main:after {
        display: none; } }
  .main .stars, .main .twinkling, .main .sky {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block; }
  .main .sky {
    background: linear-gradient(#03001e, #7303c0, #ff41cc);
    z-index: 2;
    opacity: 0.5; }

@keyframes move-twink-back {
  from {
    background-position: 0 0; }
  to {
    background-position: -10000px 5000px; } }

@-webkit-keyframes move-twink-back {
  from {
    background-position: 0 0; }
  to {
    background-position: -10000px 5000px; } }
  .main .stars {
    background: #000 url(https://res.cloudinary.com/dbeqp2lyo/image/upload/v1553376772/Portfolio/stars.png) repeat top center;
    z-index: 0; }
  .main .twinkling {
    background: transparent url(https://res.cloudinary.com/dbeqp2lyo/image/upload/v1553376772/Portfolio/twinkling.png) repeat top center;
    z-index: 1;
    -webkit-animation: move-twink-back 200s linear infinite;
    animation: move-twink-back 200s linear infinite; }
  .main-wrapper {
    position: relative;
    z-index: 3;
    -webkit-transform: translateY(10vh);
            transform: translateY(10vh); }
    .main-wrapper h1 {
      font-size: 17vw;
      font-weight: 700;
      margin: 0 auto; }
    .main-wrapper h2 {
      font-size: 4vw;
      font-weight: 100;
      margin: 0.5rem auto; }
    .main-wrapper svg {
      fill: white;
      border: 1px solid white; }
      .main-wrapper svg:hover {
        border-color: #FF4500;
        background: #FF4500; }
    @media (min-width: 460px) {
      .main-wrapper h1 {
        width: 100%;
        font-size: calc(78px + 76 * ((100vw - 460px) / 1460)); }
      .main-wrapper h2 {
        width: 100%;
        font-size: calc(18px + 20 * ((100vw - 460px) / 1460)); } }
    @media (min-width: 1920px) {
      .main-wrapper h1 {
        font-size: 154px; }
      .main-wrapper h2 {
        font-size: 38px; } }

nav {
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 999;
  transition: 0.2s;
  height: 48px;
  font-family: 'Raleway', sans-serif; }
  nav .wrapper {
    padding: 0; }
    @media (min-width: 660px) {
      nav .wrapper {
        padding: 0 1.5rem; } }
  nav ul {
    padding-left: 0;
    list-style: none;
    transition: 0.2s;
    max-height: 0px;
    overflow: hidden;
    clear: both;
    position: absolute;
    width: 100%;
    top: 30px; }
    nav ul li {
      background: rgba(0, 0, 0, 0.7); }
    @media (min-width: 660px) {
      nav ul {
        top: 0;
        position: relative;
        float: right;
        left: auto;
        width: auto;
        -webkit-transform: none;
                transform: none;
        margin: 1rem 0;
        max-height: 48px;
        overflow: visible;
        clear: none; }
        nav ul li {
          background: none;
          float: left; }
        nav ul li > a:before, nav ul li > a:after {
          content: '';
          position: absolute;
          bottom: 8px;
          width: 0px;
          height: 3px;
          transition: all 0.2s ease-in-out;
          transition-duration: 0.2s;
          opacity: 0; }
        nav ul li > a:before {
          left: calc(50%);
          background: #FF4500; }
        nav ul li > a:after {
          right: calc(50%);
          background: #FF4500; }
        nav ul li > a:hover:before, nav ul li > a:hover:after {
          width: 40%;
          opacity: 1; } }
    nav ul.menu-toggle {
      max-height: 15rem; }
  nav li > a {
    position: relative;
    text-decoration: none;
    padding: 15px;
    display: block;
    color: white;
    letter-spacing: 2px;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
    z-index: 3; }

.active {
  color: #FF4500; }
  .active:before, .active:after {
    width: 40%;
    opacity: 1; }

.logo {
  display: block;
  float: left;
  color: #FF4500;
  font-size: 2.5rem;
  font-weight: 700;
  transition: 0.2s;
  position: absolute;
  margin: 0;
  padding: 3px 5px 0; }
  @media (min-width: 660px) {
    .logo {
      left: 0;
      position: relative;
      margin: 16px 0; } }
  .logo svg {
    height: 32px;
    width: auto; }
    .logo svg path {
      transition: 0.2s ease; }
      .logo svg path.letterD {
        fill: #ff4500; }
      .logo svg path.letterP {
        fill: white; }
  .logo:hover {
    background: white; }
    .logo:hover svg path {
      transition: 0.2s ease; }
      .logo:hover svg path.letterP {
        fill: #350058; }

.dark-nav {
  background: #350058;
  box-shadow: 0 0 10px black; }
  @media (min-width: 660px) {
    .dark-nav ul {
      margin: 0; } }
  .dark-nav .logo {
    margin-top: 0; }

.navbar_button {
  position: absolute;
  right: 14px;
  transition: 0.2s;
  top: -4px; }
  .navbar_button button {
    outline: none; }
  @media (min-width: 660px) {
    .navbar_button {
      top: 12px;
      display: none; } }

.page-section {
  padding: 6rem 0;
  text-align: center; }
  .page-section_banner h2 {
    position: relative;
    width: auto;
    font-size: 2.5rem;
    font-weight: 700; }
    .page-section_banner h2::after {
      content: "";
      position: absolute;
      top: 150%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: 3px;
      width: 250px;
      background: #FF4500; }
  .page-section_banner p {
    color: #6d6d6d;
    margin: 0 auto;
    font-size: 20px;
    max-width: 400px;
    padding: 1rem 0 2rem; }
    @media (min-width: 770px) {
      .page-section_banner p {
        padding-bottom: 6rem; } }
  .page-section_banner-white h2 {
    color: white; }
    .page-section_banner-white h2::after {
      background: #FF4500; }
  .page-section_banner-white p {
    color: white; }

.projects {
  position: relative;
  z-index: 3;
  background: white; }
  .projects h3 {
    font-size: 2.2rem;
    margin: 0;
    font-weight: 400;
    padding-top: 3rem; }
    @media (min-width: 770px) {
      .projects h3 {
        padding-top: 0; } }
  .projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0; }
    .projects-container:last-child {
      padding-bottom: 37px; }
    .projects-container > div {
      width: 100%; }
      .projects-container > div p {
        text-align: left;
        margin: 0 auto;
        max-width: 540px;
        padding: 1.5rem 0;
        font-size: 1.09rem;
        line-height: 1.7rem;
        color: #757575; }
      @media (min-width: 770px) {
        .projects-container > div {
          width: 50%;
          max-width: auto; }
          .projects-container > div p {
            padding-bottom: 1rem;
            padding: 1rem 0; }
          .projects-container > div h3 {
            text-align: left; } }
    @media (min-width: 770px) {
      .projects-container {
        flex-direction: row;
        align-items: flex-start; }
        .projects-container div:first-child {
          text-align: right; }
        .projects-container div:nth-child(2) {
          padding-left: 80px; } }
  .projects-skills {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    padding-bottom: 1.3rem;
    margin: 0 auto;
    list-style: none;
    font-size: 0.9rem;
    max-width: 430px; }
    .projects-skills li {
      color: white;
      background: #54008b;
      border-radius: 1rem;
      padding: 0.2rem 0.7rem;
      margin: 0.45rem 0.5rem; }
    @media (min-width: 770px) {
      .projects-skills {
        padding-bottom: 1.8rem;
        justify-content: left;
        max-width: 100%; }
        .projects-skills li {
          margin: 0.45rem 1rem 0.45rem 0; } }
  .projects-links {
    display: flex;
    justify-content: center; }
    .projects-links a {
      text-decoration: none; }
      .projects-links a div {
        position: relative;
        overflow: hidden;
        font-size: 1.5rem;
        font-weight: bolder;
        text-transform: uppercase;
        padding: 0.28rem 1rem;
        margin: 0 1rem;
        color: #FF4500;
        box-shadow: inset 0 0 0 2px #FF4500, 0 3px #FF4500, 0 0 0px lightgray;
        transition: all 0.5s ease; }
        .projects-links a div:hover {
          box-shadow: inset 0 0 0 2px #FF4500, 0 3px #FF4500, 0 8px 10px lightgray; }
          .projects-links a div:hover:active {
            -webkit-transform: translateY(3px);
                    transform: translateY(3px);
            box-shadow: inset 0 0 0 2px #FF4500, 0 0px #FF4500, 0 5px 5px lightgray; }

.progressive {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none;
  box-shadow: 0 0 0rem #b3b3b3;
  transition: box-shadow 1s ease-out; }

.progressive:not(.replace) {
  cursor: default; }

.progressive img {
  display: block;
  width: 100%;
  max-width: none;
  height: auto;
  border: 0 none; }

.progressive img.preview {
  -webkit-filter: blur(2vw);
          filter: blur(2vw); }

.progressive img.reveal {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: reveal 1s ease-out;
          animation: reveal 1s ease-out; }

.drop-shadow {
  box-shadow: 0 0 5em #b3b3b3; }

@-webkit-keyframes reveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes reveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.skills {
  background: #f1f1f1; }
  .skills svg {
    height: 6rem;
    width: 6rem;
    padding: 15px;
    transition: 0.2s; }
    .skills svg path,
    .skills svg g {
      fill: #350058; }
    @media (min-width: 770px) {
      .skills svg {
        height: 10rem;
        width: 10rem;
        padding: 25px; } }
  .skills-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 960px; }

.wrapper {
  padding: 0 1.5rem;
  margin: 0 auto;
  max-width: 1200px; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: white; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg) rotateY(180deg);
          transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  -webkit-perspective: 80px;
          perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
          transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
          transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
          transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
            transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
          transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
            transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
              transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
              transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
              transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
              transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
            transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
            transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
          transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
            transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
            transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
            transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: transparent !important;
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  background-color: transparent !important;
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transform: rotate(765deg);
          transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-765deg);
          transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

.social-media {
  display: flex;
  justify-content: center; }
  .social-media a {
    margin: 2rem; }
  .social-media svg {
    padding: .5rem;
    height: 3rem;
    width: 3rem;
    transition: all .2s; }
